/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {graphql, useStaticQuery} from "gatsby"

import {Footer, Header} from "./index"
import "../styles.scss"

const Layout = (props) => {
    const {children} = props;
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

    return (
        <div className="ch-wrapper">
            <Header siteTitle={data.site.siteMetadata.title}
                    location={props.location}/>
            <div className="main-body">
                <div>{children}</div>
            </div>
            <Footer props={props}/>
        </div>
    )
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout
