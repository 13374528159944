import { SEGMENT_ANONYMOUS_KEY } from "../constants/CommonConstants"
import { BASE_URL } from "gatsby-env-variables"

export class SegmentAnalytics {
    static async track(event, payload) {
        let userId = localStorage.getItem(SEGMENT_ANONYMOUS_KEY)
        if (!userId) {
            userId = window.analytics.user().anonymousId()
            localStorage.setItem(SEGMENT_ANONYMOUS_KEY, userId)
        }

        if (payload.email) {
            await fetch(BASE_URL + "/auth/website/provisionalUser", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    email: payload.email,
                }),
            }).then(response => response.json())
                .then(response=> {
                    userId = response.userId;

                    localStorage.setItem(SEGMENT_ANONYMOUS_KEY, userId);
                    window.analytics.identify(userId, {
                        email: payload.email,
                    });
                });

        } else if (payload.phone) {
            window.analytics.identify(userId, {
                phone: payload.phone,
            })
        } else {
            window.analytics.identify(userId)
        }
        window.analytics.track(event, payload, () => {
            console.log(
                `Track event ${event} sent to segment with payload ${payload} and user id -- ${userId}`)
        })
    }

}