import {Link} from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from 'react'
import Lottie from 'react-lottie'
import AlfieFace from '../assets/animation/alfie-face'
import MenuBars from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal';

let activePath = 'abc';
const Header = (props) => {

    const {location} = props;
    const [isOpened, setIsOpened] = useState(false);
    const [isShown, setShow] = useState(
        location && location.hash === "#get-started");

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }

    return (
        <header className="header-row">
            <div className="inner-header">
                <div className="logo-section">
                    <span>
                        <Link to="/" className="logo-box">
                            <div className="oval">
                                <Lottie options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: AlfieFace
                                }}
                                        height={76}
                                        width={76}
                                />
                            </div>
                            <p className="logo-title">Confidant Health</p>
                        </Link>
                    </span>
                </div>
                <div className="menu-section">
                    <div className="bars-menu">
                        <Button className="bar-ico" onClick={toggle}>
                            {
                                isOpened ? <CloseIcon/> : <MenuBars/>
                            }
                        </Button>
                        {
                            isOpened && (
                                <div className="bar-list animated fadeInDown">
                                    <ul>
                                        <li>
                                            <Link
                                                className={activePath === 'about-us' ?
                                                    "menu-text active" : "menu-text"}
                                                to="/about-us/"
                                                onClick={() => {
                                                    activePath = 'AboutUs'
                                                }}>
                                                About
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={activePath === 'Manifesto' ?
                                                    "menu-text active" : "menu-text"}
                                                to="/manifesto/"
                                                onClick={() => {
                                                    activePath = 'Manifesto'
                                                }}>
                                                Manifesto
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={activePath === 'WhoItsFor' ?
                                                    "menu-text active" : "menu-text"}
                                                to="/who-is-it-for/"
                                                onClick={() => {
                                                    activePath = 'WhoItsFor'
                                                }}>
                                                Who it's for
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={activePath === 'HowItWorks' ?
                                                    "menu-text active" : "menu-text"}
                                                to="/how-it-works/"
                                                onClick={() => {
                                                    activePath = 'HowItWorks'
                                                }}>
                                                How it Works
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={activePath === 'LetterToLovedOnes' ?
                                                    "menu-text active" : "menu-text"}
                                                to="/letter-to-loved-ones"
                                                onClick={() => {
                                                    activePath = 'LetterToLovedOnes'
                                                }}>
                                                Letter to Loved Ones
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className={activePath === 'learning-library' ?
                                                    "menu-text active" : "menu-text"}
                                                to="/learning-library/"
                                                onClick={() => {
                                                    activePath = 'learning-library'
                                                }}>
                                                Learning Library
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                id="get-started"
                                                className={activePath === 'GetStarted' ?
                                                    "menu-text active" : "menu-text"}
                                                to="/get-started/"
                                                onClick={() => {
                                                    activePath = 'GetStarted'
                                                }}>
                                                Get Started
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )
                        }

                        <Modal
                            className="modalStyle1"
                            open={isShown || (typeof window !== 'undefined' &&
                                window.location && window.location.hash === "#get-started")}
                            setShow={setShow}
                            onClose={() => setShow(false)}
                            disableScrollLock={true}>
                        </Modal>
                    </div>
                    <ul className="menu-ul">
                        <li className="menu-item">
                            <Link
                                className={activePath === 'AboutUs' ?
                                    "menu-text active" : "menu-text"}
                                to="/about-us/"
                                onClick={() => {
                                    activePath = 'AboutUs'
                                }}>
                                About
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                className={activePath === 'Manifesto' ?
                                    "menu-text active" : "menu-text"}
                                to="/manifesto/"
                                onClick={() => {
                                    activePath = 'Manifesto'
                                }}>
                                Manifesto
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                className={activePath === 'WhoItsFor' ?
                                    "menu-text active" : "menu-text"}
                                to="/who-is-it-for/"
                                onClick={() => {
                                    activePath = 'WhoItsFor'
                                }}>
                                Who it's for
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                className={activePath === 'HowItWorks' ?
                                    "menu-text active" : "menu-text"}
                                to="/how-it-works/"
                                onClick={() => {
                                    activePath = 'HowItWorks'
                                }}>
                                How it Works
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                className={activePath === 'LetterToLovedOnes' ?
                                    "menu-text active" : "menu-text"}
                                to="/letter-to-loved-ones/"
                                onClick={() => {
                                    activePath = 'LetterToLovedOnes'
                                }}>
                                Letter to Loved Ones
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link
                                className={activePath === 'learning-library' ?
                                    "menu-text active" : "menu-text"}
                                to="/learning-library/"
                                onClick={() => {
                                    activePath = 'learning-library'
                                }}>
                                Learning Library
                            </Link>
                        </li>
                        <li id="get-started" className="menu-item btn">
                            <Link
                                className={activePath === 'GetStarted' ?
                                    "menu-text active" : "menu-text"}
                                to="/get-started/"
                                onClick={() => {
                                    activePath = 'GetStarted'
                                }}>
                                Get Started
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </header>
    )
};

Header.propTypes = {
    siteTitle: PropTypes.string,
};
Header.defaultProps = {
    siteTitle: `Confidant`,
};

export default Header
