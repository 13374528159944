import React, {useState} from "react"
import TextField from '@material-ui/core/TextField'
import ArrowNavigationIcon from '@material-ui/icons/NearMe'
import Lottie from 'react-lottie'
import AlfieFace from '../assets/animation/alfie-face'
import Button from "@material-ui/core/Button";
import PatternSvg from "../assets/images/footer-bg-pattern.svg";
import {SegmentAnalytics} from "../common/utils/SegmentAnalytics";
import {SegmentEvents} from "../common/utils/SegmentSpec";
import {isValidEmail, isValidPhone} from "../common/utils/CommonUtils";
import {BranchUtils} from "../common/utils/BranchUtils";
import { graphql, StaticQuery } from "gatsby"

const getFooterImage = (currentPathName) => {
    switch (currentPathName) {
        case "about-us": {
            return require("../assets/images/about-hero-img.svg")
        }
        case "manifesto": {
            return require("../assets/images/footer-image-1.png")
        }
        case "who-is-it-for": {
            return require("../assets/images/footer-image-2.svg")
        }
        case "how-it-works": {
            return require("../assets/images/footer-image-3.png")
        }
        case "letter-to-loved-ones": {
            return require("../assets/images/footer-image-4.png")
        }
        case "meet-the-team" : {
            return require("../assets/images/footer-image-5.svg")
        }
        default: {
            return require("../assets/images/home-hero-img.png")
        }
    }
};

const FooterServices = ()=>{

    return (
        <StaticQuery
            query={graphql`
            query FooterServicesQuery {
                allContentfulServicePage {
                    nodes {
                        id
                        serviceName
                        slug   
                    }
                  }
              }  
      `}
            render={data => {

                const services = data.allContentfulServicePage.nodes;
                let slice1, slice2 = [];
                if(services.length>7) {
                    slice1 = services.slice(0,7);
                    slice2 = services.slice(7);
                } else {
                    slice1 = services;
                }
                if(slice1.length===0) {
                    return null;
                }

                return (
                    <>
                        <ul className="footer-ul">
                            <p className="footer-head">Services</p>
                            {
                                slice1.map(service=>{
                                    return (
                                        <li className="footer-item" key={service.id}>
                                            <a href={`/${service.slug}/`}>{service.serviceName}</a>
                                        </li>
                                    )
                                })
                            }

                        </ul>
                        {
                            slice2.length>0 && (
                                <ul className={'footer-ul'}>
                                    <p className="footer-head" style={{visibility: 'hidden'}}>Services</p>
                                    {
                                        slice2.map(service=>{
                                            return (
                                                <li className="footer-item" key={service.id}>
                                                    <a href={`/${service.slug}/`}>{service.serviceName}</a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            )
                        }
                    </>

                )
            }}
        />
    )
};

const Footer = () => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const sendPhone = (event, phoneNumber) => {
        event.preventDefault();
        const phone = isValidPhone(phoneNumber);
        if (phone) {
            let currentPage = typeof window !== 'undefined'
                ? window.location.pathname : '';
            currentPage = currentPage.replace('/', '');
            if (currentPage === "") {
                currentPage = "home-page"
            }
            let fromPage = currentPage + '-download-the-app';

            SegmentAnalytics.track(
                SegmentEvents.USER_REQUESTED_FOR_APP_FROM_FOOTER, {
                    phone,
                    'formLocation': fromPage,
                    appRequested: true,
                    category: 'Get The App',
                    label: 'Phone'
                });
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': fromPage,
                'event': 'GET_THE_APP',
                'phone': phone
            });

            BranchUtils.desktopSendSMS(phone, fromPage, 'Bottom CTA', fromPage );

            setPhone('');
            setPhoneSuccess(true);
            setPhoneError(false);
        } else {
            setPhoneError(true);
            setPhoneSuccess(false);
        }
    };

    const sendEmail = (event, email) => {
        event.preventDefault();
        if (isValidEmail(email)) {
            let currentPage = typeof window !== 'undefined'
                ? window.location.pathname : '';
            currentPage = currentPage.replace('/', '');
            if (currentPage === "") {
                currentPage = "home-page"
            }

            SegmentAnalytics.track(
                SegmentEvents.SIGN_UP_FOR_THE_LATEST_NEWS_ARTICLES_AND_MORE, {
                    email,
                    'formLocation': currentPage + '-footer',
                    appRequested: true,
                    category: 'Newsletter',
                    label: 'Email'
                });

            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'formLocation': currentPage + '-footer',
                'event': 'NEW_SUBSCRIBER',
                'email': email
            });
            setEmail("");
            setEmailSuccess(true);
            setEmailError(false);

        } else {
            setEmailError(true);
            setEmailSuccess(false);
        }
    };

    let currentPathName = typeof window !== 'undefined'
        ? window.location.pathname : '';
    currentPathName = currentPathName.replace('/', '');
    const footerImage = getFooterImage(currentPathName);
    return (
        <div className="footer-wrapper">
            <div className="footer-pattern-section"
                 style={{backgroundImage: `url(${PatternSvg})`}}>
                <div className="footer-pattern-inner">
                    <img className="footer-hero-img"
                         style={
                             footerImage === require(
                                 "../assets/images/footer-image-3.png") ? {
                                 top: -290, right: 10, maxWidth: 600
                             } : null
                         }
                         src={footerImage} alt="iPhone "/>
                    <div className="footer-pattern-content">
                        <p className="footer-sub-head">Download the app</p>
                        <h2 className="footer-main-head">Start thriving <br/>
                            without pressing pause</h2>
                        <form method="post"
                              onSubmit={(event) => sendPhone(event, phone)}>
                            <div className="get-app-input-wrap">
                                <div className="input-box">
                                    <TextField
                                        id="number-input"
                                        className="number-field"
                                        placeholder="Your phone number"
                                        margin="normal"
                                        value={phone}

                                        onChange={event => {
                                            const {value} = event.target;
                                            setPhone(value);
                                            setPhoneSuccess(false);
                                            setPhoneError(false)
                                        }}
                                    />
                                </div>
                                <Button className="blue-arrow-btn" type="submit">
                                    Get the App <ArrowNavigationIcon/>
                                </Button>
                                {phoneError ? <p className="error-msg">Please
                                    Enter Valid Phone</p> : null}
                                {phoneSuccess ? <p
                                    className="success-msg">Got it! Sending you a text with the link</p> : null}
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div className="new-footer-wrapper">
                <div className="new-footer-inner">
                    <div className="elfie-box">
                        <div className="oval">
                            <Lottie options={{
                                loop: true,
                                autoplay: true,
                                animationData: AlfieFace
                            }}
                                    height={64}
                                    width={64}
                            />
                        </div>
                    </div>
                    <div className="footer-menu">
                        <ul className="footer-ul">
                            <p className="footer-head">Learn More</p>
                            <li className="footer-item">
                                <a href="/online-therapy/">Online Therapy</a>
                            </li>
                            <li className="footer-item">
                                <a href="/how-to-guides/">How to Guides</a>
                            </li>
                            <li className="footer-item">
                                <a href="/support-groups/">Support Groups</a>
                            </li>
                            <li className="footer-item">
                                <a href="/letter-to-loved-ones/">Letter to Loved
                                    Ones</a>
                            </li>
                            <li className="footer-item">
                                <a href="/how-it-works/">How it Works</a>
                            </li>
                            <li className="footer-item">
                                <a href="/community-payment/">Community Payment</a>
                            </li>
                            <li className="footer-item">
                                <a href="/learning-library/">Learning Library</a>
                            </li>
                        </ul>
                        <FooterServices/>
                        <ul className="footer-ul">
                            <p className="footer-head">Company</p>
                            <li className="footer-item">
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://apps.apple.com/us/app/confidant-health/id1478727129">Download
                                    App</a>
                            </li>
                            <li className="footer-item">
                                <a href="/about-us/">About us</a>
                            </li>
                            <li className="footer-item">
                                <a href="/manifesto/">Manifesto</a>
                            </li>
                            <li className="footer-item">
                                <a href="/meet-the-team/">Meet the Team</a>
                            </li>
                            <li className="footer-item">
                                <a href="/diversity/">Diversity</a>
                            </li>
                            <li className="footer-item">
                                <a href="/locations/">Locations</a>
                            </li>

                        </ul>
                        <ul className="footer-ul">
                            <p className="footer-head">Resources</p>
                            <li className="footer-item">
                                <a href="/privacy-policy/">Privacy Policy</a>
                            </li>
                            <li className="footer-item">
                                <a href="/terms-and-conditions/">Terms and
                                    Conditions</a>
                            </li>
                            <li className="footer-item">
                                <a href="/sitemap.xml">Sitemap</a>
                            </li>
                        </ul>
                    </div>
                    <div className="new-footer-number">
                        <p className="btm-number-text">203.767.8696</p>


                        <a href="http://legitscript.com/pharmacy/confidanthealth.com"
                           rel="noreferrer" target="_blank"
                           title="Verify LegitScript Approval">
                            <img className="footer-legit-img"
                                 src="https://static.legitscript.com/seals/5461895.png"
                                 alt="LegitScript approved" width="140"
                                 height="100" border="0"/>
                        </a>


                    </div>
                </div>
            </div>
            <div className="top-footer">
                <div className="footer-signup">
                    <p className="signup-text">Sign up for the latest news,
                        articles, and more!</p>
                    <form method="post"
                          onSubmit={(event) => sendEmail(event, email)}>
                        <div className="signup-input-wrap">
                            <div className="input-box">
                                <TextField
                                    id="email-input"
                                    className="email-field"
                                    placeholder="Enter your email"
                                    value={email}
                                    margin="normal"
                                    onChange={event => {
                                        const {value} = event.target;
                                        setEmail(value);
                                        setEmailError(false);
                                        setEmailSuccess(false);
                                    }}
                                />
                            </div>
                            <button id="submit-email" className="subscribe-btn" type="submit">
                                Subscribe
                            </button>
                            {emailError ?
                                <p className="error-msg">Please Enter Valid Email{emailError}</p> : null}
                            {emailSuccess ?
                                <p className="success-msg">Subscribed Successfully {emailSuccess}</p> : null}
                        </div>
                    </form>
                </div>
            </div>
            <div className="bottom-footer">
                <div className="btm-inner">
                    <p className="footer-text">
                        &copy; Confidant Health {new Date().getFullYear()}
                    </p>
                    <p className="footer-text">177 West Putnam Avenue Greenwich, CT 06830 | 203.767.8696</p>
                </div>
            </div>
        </div>
    )
};
export default Footer
