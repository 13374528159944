export const SegmentEvents = {
    USER_REQUEST_FOR_JOIN_GROUP_GET_THE_APP: "Requested App After Group Joined",
    GET_STARTED_WITH_CONFIDANT: "Requested App By Text From Web",
    USER_REQUESTED_FOR_APP_FROM_FOOTER: "Requested App By Text",
    SIGN_UP_FOR_THE_LATEST_NEWS_ARTICLES_AND_MORE: "Joined Newsletter From Web",
    USER_REQUEST_FOR_COURSE_GET_THE_APP: "Requested App After Course Sign Up",
    USER_REQUESTED_FOR_APP_FROM_PROVIDER_PAGE: "Requested App By Text From Web",
    USER_REQUESTED_FOR_APPOINTMENT: "Requested An Appointment From Web",
    GROUP_LANDING_PAGE: "Requested App By Text From Web",
    USER_REQUESTED_FOR_JOIN_GROUP: "Requested to Join Group From Web",
    LEARN_WITH_CONFIDANT_COURSES: "Requested App By Text From Web",
    USER_REQUESTED_FOR_COURSE: "Requested Course From Web",
    USER_REQUESTED_FOR_CONNECT_PROVIDER: "Requested An Appointment From Web",
    USER_GET_APP_REQUESTED: "Requested App By Text From Web",
    USER_REQUESTED_FOR_APP_FROM_CONNECT_PROVIDER_PAGE: "Requested App By Text",
    USER_REQUESTED_FOR_APP_FROM_LEARNING_LIBRARY_PAGE: "Requested App By Text From Web",

};